import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.CATALOG.PAGE_VIEW, ({ category, products, categoryStore }) => {
  window.dataLayer.push({
    category: category.title,
    category_id: categoryStore.id,
    category_title: categoryStore.title,
    category_product_id: products.map(product => product.marketingCode),
    event: 'categoryView',
  })
})
