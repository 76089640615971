import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'
import itemTransformer from '~/events/dataLayerBasic/transformers/item.transformer'

eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
  window.dataLayer.push({
    cart_id: order.attributes.cartId,
    event: eventDefinitions.ORDER.PLACED,
    order_id: order.id,
    order_items: order.items.map(item => itemTransformer(item)),
    order_prices_totalWithTax: order.prices.totalWithTax,
    order_prices_totalWithoutTax: order.prices.totalWithoutTax,
    order_prices_itemsWithTax: order.prices.itemsWithTax,
    order_prices_itemsWithoutTax: order.prices.itemsWithoutTax,
    order_productCodes: order.items
      .map(item => item.product)
      .filter(item => item)
      .map(product => product.marketingCode),
    order_variantCodes: order.items.map(item => item.shoppable).map(shoppable => shoppable.marketingCode),
    totalAmount: order.items.reduce((acc, item) => acc + item.amount, 0),
    customer_email: order.customer.email,
    customer_name: order.customer.name,
  })

  if (order.customer.newsletter) {
    window.dataLayer.push({
      event: 'gaevent',
      category: 'form',
      action: 'odeslat',
      label: 'newsletter',
      email: order.customer.email,
    })
  }
})
