import { merge } from 'lodash'
import contentSettings from '~/sites/shop/content.settings'

const overloadSettings = {
  ean: {
    pathHash: '#ProductDetail__content',
  },
  events: {
    sendProductsInFirstCheckoutStepOnly: true,
    enhancedEcommerce: {
      purchase: {
        unitsWithTax: false,
        revenueIncludeShipperPrice: false,
      },
    },
    heureka: {
      conversions: {
        enable: true,
      },
      shopCertification: {
        enable: true,
      },
    },
  },
  functions: {
    shoppingLists: true,
  },
  foxentry: {
    id: 'xhgTR28WRZ',
    routes: ['RouteRegister', 'RouteCheckoutInfo'],
  },
  giftPrice: 0.01,
}

export default merge(contentSettings, overloadSettings)
