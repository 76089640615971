import AppLink from '@theme/components/atom/AppLink'

export default {
  components: {
    AppLink,
  },
  props: {
    links: {
      type: Array,
    },
  },
}
