import AppButton from '@theme/components/atom/AppButton'
import AppImage from '@theme/components/atom/AppImage'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppButton,
    AppImage,
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock, DetectMobileMixin],
  cmsSettings: {
    type: 'landing_product_cards',
  },
  data() {
    return {
      products: [],
    }
  },
  async mounted() {
    this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', {
      ids: this.block.product_cards.map(card => card.product_reference[0].entityId),
    })
  },
  query() {
    return `
      ... on block_landing_product_cards {
        type
        uuid
        product_cards {
          ... on landing_block_product_card {
            position
            title
            tag
            description
            image {
              path
              alt
            }
            product_reference {
              entityId
            }
            button_color
          }
        }
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            offsetX
            offsetY
            width
            height
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
