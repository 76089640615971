import { mutations, actions as parentActions, state } from '~/sites/shop/store/category'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async FETCH_TOP_CATEGORIES({ commit, dispatch }) {
    try {
      const cmsResponse = await this.app.apolloProvider.defaultClient.query({
        query: gql`
          query {
            configuration_top_categories {
              top_categories {
                ... on top_category {
                  top_category_title
                  top_virtual_categories {
                    entityId
                    entity {
                      ... on virtual_category {
                        title
                        page_meta {
                          slug
                        }
                      }
                    }
                  }
                  top_categories {
                    entityId
                    entity {
                      ... on StoreCategory {
                        data
                      }
                    }
                  }
                  title
                  top_category_item_type
                  top_category_image {
                    path
                    alt
                  }
                  top_category_links {
                    ... on link_embedded {
                      title
                      link_type
                      custom_link
                      category_link {
                        entity {
                          ... on StoreCategory {
                            data
                          }
                        }
                      }
                      virtual_category_link {
                        entity {
                          ... on virtual_category {
                            title
                            page_meta {
                              slug
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
      })
      commit('SET_TOP_CATEGORIES', cmsResponse.data.configuration_top_categories.top_categories)
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions, state, mutations }
