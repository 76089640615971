<template>
  <component
    :is="wysiwygWrapperType"
    class="Wysiwyg">
    <slot/>
  </component>
</template>

<script>
import WysiwygNodeMixin from '@theme/components/utils/Wysiwyg/mixins/WysiwygNodeMixin'

export default {
  mixins: [WysiwygNodeMixin],
  props: {
    wysiwygWrapperType: {
      type: String,
      default: 'div',
    },
  },
}
</script>
