import productTransformer from './transformers/product.transformer'

export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.CART.ITEM.ADD, ({ addedAmount, item }) => {
    const unitsWithTax = context.app.$contentSettings.events.unitsWithTax
    if (item.shoppable && item.product) {
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode: item.prices.currencyCode,
          add: {
            products: [productTransformer(item, unitsWithTax, addedAmount)],
          },
        },
        price_with_VAT: item.prices.unitWithTax,
        product_add_id: item.variant.marketingCode,
      })
    }
  })
}
