import { mutations, actions as parentActions, state } from '~/sites/shop/store/product'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async FETCH_PARAMETERS({}, { product }) {
    // TODO: Replace with one unified product get GraphQL query
    if (product.content.parameters) {
      try {
        const parameters = []
        const parameterIds = []
        const valueIds = []

        // Acquire content ids that will be loaded
        parameters.push(...product.content.parameters)
        product.variants.forEach(variant => {
          if (variant.content && variant.content.parameters) {
            parameters.push(...variant.content.parameters)
          }
        })
        parameters.forEach(parameter => {
          parameterIds.push(parameter.entityId)
          if (parameter.values && Array.isArray(parameter.values)) {
            parameter.values.forEach(valueId => {
              valueIds.push(valueId)
            })
          }
        })

        /**
         * To extend following graphql queries (i.e. adding a field)
         * do not forget that you must add it to parameter_value as well.
         */
        // TODO: @GF: Refactor query - Apollo is matching all entities on each fragment.
        // TODO: @GF: So it works in web playground but not via Apollo client.
        const client = this.app.apolloProvider.defaultClient
        const query = await client.query({
          query: gql`
            query($parameterIds: [String], $valueIds: [String]) {
              virtual_category(filters: { parameters: { entity_id: $parameterIds, values: $valueIds } }) {
                entities {
                  title
                  page_meta {
                    slug
                  }
                  parameters {
                    entityId
                    values
                  }
                }
              }
              Content(filters: { _id: $valueIds }) {
                entities {
                  ... on certification {
                    _id
                    description_short
                    description_long
                    parameter_value_image {
                      path
                      alt
                    }
                    title
                    weight
                  }
                  ... on contraindication {
                    _id
                    description_short
                    description_long
                    parameter_value_image {
                      path
                      alt
                    }
                    title
                    weight
                  }
                  ... on parameter_value {
                    _id
                    description_short
                    description_long
                    parameter_value_image {
                      path
                      alt
                    }
                    title
                    weight
                  }
                }
              }
              parameter(filters: { _id: $parameterIds }) {
                entities {
                  _id
                  title
                  parameter_type
                  parameter_unit
                  link_to_virtual_category
                  description
                  parameter_hide_product_detail
                }
              }
            }
          `,
          variables: {
            parameterIds,
            valueIds,
          },
        })

        // Assign entities to parameters and values
        parameters.forEach(parameter => {
          parameter.entity = query.data.parameter.entities.filter(p => p._id === parameter.entityId)[0]
          parameter.valueEntities = []
          if (parameter.values && Array.isArray(parameter.values)) {
            parameter.values.forEach(valueId => {
              const value = query.data.Content.entities.filter(v => v._id === valueId)[0]
              if (value) {
                // Append virtual category to the value if exists and has only one parameter and value sliced.
                value.virtualCategory = query.data.virtual_category.entities.find(category => {
                  const virtualCategoryParameter = category.parameters.find(
                    parameter => parameter.entityId === parameter.entityId,
                  )
                  return (
                    virtualCategoryParameter &&
                    virtualCategoryParameter.values.length === 1 &&
                    virtualCategoryParameter.values[0] === valueId &&
                    (!virtualCategoryParameter.sliced_categories ||
                      virtualCategoryParameter.sliced_categories.length === 0)
                  )
                })
                parameter.valueEntities.push(value)
              }
            })
          }
        })

        // Filter empty parameters
        product.content.parameters = product.content.parameters.filter(
          p =>
            p.entity &&
            p.values &&
            ((p.entity.parameter_type[0] === 'number' && p.values > 0) || p.values.length > 0) &&
            !p.entity.parameter_hide_product_detail,
        )
      } catch (e) {
        console.error(e)
      }
    }
  },
}

export { actions, mutations, state }
