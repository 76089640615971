<script>
import ProductCreditsMixin from '~/sites/shop/mixins/ProductCreditsMixin'
const SALES_PROMOTION_CATEGORY_CODES = ['bag_samples', 'sales_promotion', 'others', 'essential_oils_testers_pp']

export default {
  extends: ProductCreditsMixin,
  computed: {
    creditPaymentPossible() {
      return this.isEmployeeUser || (this.isBusinessUser && this.hasSalesPromotion)
    },
    hasSalesPromotion() {
      return (
        this.product.categoryCodes &&
        this.product.categoryCodes.some(categoryCode => SALES_PROMOTION_CATEGORY_CODES.includes(categoryCode))
      )
    },
    isBusinessUser() {
      return false
    },
    isEmployeeUser() {
      return false
    },
  },
}
</script>
