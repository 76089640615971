import { get } from 'lodash'
import AppButton from '@theme/components/atom/AppButton'
import CartInfo from '@theme/components/cart/CartInfo'
import DomainSwitcher from '@theme/components/shop/DomainSwitcher'
import Dropdown from '@theme/components/utils/Dropdown'
import DropdownContent from '@theme/components/utils/DropdownContent'
import DropdownLinks from '@theme/components/utils/DropdownLinks'
import HeaderSearch from '@theme/components/shop/HeaderSearch/'
import IconLink from '@theme/components/molecule/IconLink'
import LanguageSwitcher from '@theme/components/shop/LanguageSwitcher'
import Logo from '@theme/components/molecule/Logo'
import LogoutButton from '@theme/components/user/LogoutButton'
import MainMenuMobile from '@theme/components/shop/MainMenuMobile'
import SearchInput from '@theme/components/shop/SearchInput'
import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  components: {
    AppButton,
    CartInfo,
    DomainSwitcher,
    LanguageSwitcher,
    Dropdown,
    DropdownContent,
    DropdownLinks,
    HeaderSearch,
    IconLink,
    Logo,
    LogoutButton,
    MainMenuMobile,
    SearchInput,
    TooltipOpener,
  },
  mounted() {
    this.initCartInfoDropdown()
  },
  data() {
    return {
      cartInfoDropDownComponent: null,
      open: false,
      userMenu: this.$store.state.globals.userMenu,
    }
  },
  computed: {
    activeLanguage() {
      return this.$store.state.globals.languages.find(language => language.code === this.$i18n.locale)
    },
    currencyLinks() {
      let links = []
      this.$store.state.globals.currencies.forEach(currency => {
        links.push({
          path: {
            name: 'index',
          },
          title: currency,
        })
      })
      return links
    },
    isBusinessUser() {
      return false
    },
    userCredits() {
      const amount = this.$store.state.user.userCredits
      if (this.$store.state.currency.activeCurrency.code === 'CZK') {
        return Math.floor(amount)
      }
      return amount
    },
    userCreditsMinusCart() {
      return get(this.$store, 'state.user.isLoaded', false)
        ? this.$store.state.user.userCredits - get(this.$store.state, 'cart.cartData.credits', 0)
        : this.$store.state.user.userCredits
    },
  },
  methods: {
    initCartInfoDropdown() {
      this.$refs.cartInfoDropDown.$on('init', () => {
        import('@theme/components/cart/CartInfoDropDownContent').then(component => {
          this.cartInfoDropDownComponent = component.default
          this.$refs.cartInfoDropDown.updatePosition()
        })
      })
    },
    toggleMobileMenu() {
      this.$refs.MainMenuMobile.toggleMenu()
      this.open = !this.open
    },
    toggleSearch(e) {
      e.preventDefault()
      this.$store.state.search.headerSearchInputOpen
        ? this.$store.commit('search/CLOSE_HEADER_SEARCH_INPUT')
        : this.$store.commit('search/OPEN_HEADER_SEARCH_INPUT')
    },
  },
}
