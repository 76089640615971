import Container from '@theme/components/utils/Container'

export default {
  components: {
    Container,
  },
  data() {
    return {
      url: `https://policies.google.com/technologies/cookies?hl=${this.$i18n.locale}`,
      visible: false,
    }
  },
  mounted() {
    this.visible = !this.$store.state.globals.cookieConsentClosed
  },
  methods: {
    hide() {
      this.$store.commit('globals/CLOSE_COOKIE_CONSENT')
      this.visible = false
    },
  },
}
