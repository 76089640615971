import AppButton from '@theme/components/atom/AppButton'
import LoadingMixin from '~/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  components: { AppButton },
  props: {
    button: String,
    icon: String,
    iconClasses: {
      type: String,
      default: 'mr-2 mb-1',
    },
  },
  methods: {
    async logout() {
      this.startLoading()
      await this.$store.dispatch('auth/LOGOUT')
    },
  },
}
