import BigTitleBlock from '@theme/blocks/BigTitleBlock'
import CategoriesBlock from '@theme/blocks/CategoriesBlock'
import CollapsibleBlock from '@theme/blocks/CollapsibleBlock'
import LandingBannerBlock from '@theme/blocks/LandingBannerBlock'
import LandingContentListBlock from '@theme/blocks/LandingContentListBlock'
import LandingContentVideosBlock from '@theme/blocks/LandingContentVideosBlock'
import LandingProductBoxesBlock from '@theme/blocks/LandingProductBoxesBlock'
import LandingProductCardsBlock from '@theme/blocks/LandingProductCardsBlock'
import LandingTextBlock from '@theme/blocks/LandingTextBlock'
import NewsletterBlock from '@theme/blocks/NewsletterBlock'
import ProductBlock from '@theme/blocks/ProductsBlock'
import QuoteBlock from '@theme/blocks/QuoteBlock'
import TextBlock from '@theme/blocks/TextBlock'
import TextBoxesBlock from '@theme/blocks/TextBoxesBlock'
import UspBlock from '@theme/blocks/UspBlock'

export default {
  BigTitleBlock,
  CategoriesBlock,
  CollapsibleBlock,
  LandingBannerBlock,
  LandingContentListBlock,
  LandingContentVideosBlock,
  LandingProductBoxesBlock,
  LandingProductCardsBlock,
  LandingTextBlock,
  NewsletterBlock,
  ProductBlock,
  QuoteBlock,
  TextBlock,
  TextBoxesBlock,
  UspBlock,
}
