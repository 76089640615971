import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppImage,
    AppTitle,
    Wysiwyg,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'landing_content_videos',
  },
  mounted() {
    console.log(this.block)
  },
  query() {
    return `
      ... on block_landing_content_videos {
        type
        uuid
        title
        description
        videos {
          ... on landing_block_content_video {
            url
            title
            description
          }
        }
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            width
            height
            offsetX
            offsetY
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
