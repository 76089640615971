import LandingProductBoxesBlock from '~/themes/base/blocks/LandingProductBoxesBlock'

export default {
  ...LandingProductBoxesBlock,
  query() {
    return `
      ... on block_landing_product_boxes {
        type
        uuid
        product_boxes {
          ... on landing_block_product_box {
            title
            tag
            description
            image {
              path
              alt
            }
            product_reference {
              entityId
            }
            button_color
            cta_label
          }
        }
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            width
            height
            offsetX
            offsetY
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
