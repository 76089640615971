<script>
export default {
  props: {
    forCredits: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isForCredits() {
      return this.forCredits || !!this.$route.query.for_credits
    },
    creditsCost() {
      return Math.round(this.product.prices.originalWithTax)
    },
    enoughUserCredits() {
      return this.isForCredits &&
        this.$store.state.user.userCredits &&
        this.$store.state.user.userCredits > this.creditsCost
        ? true
        : false
    },
  },
}
</script>
