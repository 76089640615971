import Dropdown from '@theme/components/utils/Dropdown'
import Loader from '@theme/components/utils/Loader'
import LoadingMixin from '~/mixins/LoadingMixin'

export default {
  mixins: [LoadingMixin],
  components: {
    Dropdown,
    Loader,
  },
  props: {
    id: String,
    dropdown: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.LanguageSwitcher.dropdown
      },
    },
    dropdownTriggerType: {
      type: Array,
      default() {
        return this.$themeSettings.components.LanguageSwitcher.dropdownTriggerType
      },
    },
    showActiveLanguage: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.LanguageSwitcher.showActiveLanguage
      },
    },
    iconActive: {
      type: [Boolean, String],
      default() {
        return this.$themeSettings.components.LanguageSwitcher.icon.active
      },
    },
    iconInactive: {
      type: [Boolean, String],
      default() {
        return this.$themeSettings.components.LanguageSwitcher.icon.inactive
      },
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
    switcherType: {
      type: [Boolean, String],
      default() {
        return this.$themeSettings.components.LanguageSwitcher.type
      },
    },
    selectedIcon: {
      type: [Boolean, String],
      default() {
        return this.$themeSettings.components.LanguageSwitcher.selectedIcon
      },
    },
    shortSelectedName: {
      type: Boolean,
      default() {
        return this.$themeSettings.components.LanguageSwitcher.shortSelectedName
      },
    },
  },
  data() {
    return {
      icons: {
        cs: require('@icon/countries/cs.svg'),
        de: require('@icon/countries/de.svg'),
        en: require('@icon/countries/en.svg'),
        gb: require('@icon/countries/gb.svg'),
        hu: require('@icon/countries/hu.svg'),
        pl: require('@icon/countries/pl.svg'),
        sk: require('@icon/countries/sk.svg'),
      },
    }
  },
  computed: {
    activeLanguage() {
      return this.$store.state.globals.languages.find(language => language.code === this.$i18n.locale)
    },
    dropdownId() {
      return this.id || 'LanguageSwitcher'
    },
    iconOpen() {
      return this.$themeSettings.components.LanguageSwitcher.selectedIcon
        ? require('@icon/down-chevron.svg?raw')
        : false
    },
    languages() {
      const links = []
      this.$store.state.globals.languages.forEach(language => {
        const { code, native } = language || {}
        const { url, sameDomain } = this.getLanguageURL(code)
        if (url) {
          links.push({
            code,
            href: url || this.localePath('index', code),
            native,
            sameDomain,
          })
        }
      })
      return links
    },
  },
  methods: {
    formatCurrencyName(currency) {
      return this.switcherType === 'name'
        ? currency.name
        : this.switcherType === 'symbol'
          ? currency.symbol
          : currency.code
    },
    getLanguageURL(code) {
      if (!code) {
        return
      }
      const { useAlternativeLinks, useCanonicalLinks, useHomepageLinks, useStorefrontUrl } =
        this.$themeSettings.components.LanguageSwitcher || {}
      const { url: canonicalURL } = this.$store.state.locale.canonicalUrls.find(i => i.language === code) || {}
      const { url: alternativeURL } = this.$store.state.locale.alternativeUrls.find(i => i.language === code) || {}
      const sameDomain = this.$i18n.locales.find(l => l.code === code) || false
      const localePathHP = useHomepageLinks ? this.localePath('index', code) : false
      let url = useHomepageLinks
        ? localePathHP
        : useAlternativeLinks
          ? alternativeURL
          : useCanonicalLinks
            ? canonicalURL
            : alternativeURL || canonicalURL || localePathHP

      const { domain: alternativeDomain } =
        this.$store.state.locale.alternativeDomains.find(i => i.language === code) || {}
      const { domain: storefrontDomain } = this.$store.state.globals.languages.find(i => i.code === code) || {}
      const { languageCode, storefrontUrl } = storefrontDomain || {}
      // Replace domain with storefrontUrl if is defined and is different
      if (url && useStorefrontUrl && languageCode === code && storefrontUrl && alternativeDomain !== storefrontUrl) {
        url = url.replace(alternativeDomain, storefrontUrl)
      }
      return {
        url,
        sameDomain: !!sameDomain,
      }
    },
    getShortName(code) {
      return code == 'cs' ? 'cz' : code
    },
  },
}
