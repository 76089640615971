import AppLink from '@theme/components/atom/AppLink'
import Dropdown from '@theme/components/utils/Dropdown'

export default {
  components: {
    AppLink,
    Dropdown,
  },
  methods: {
    dropdownHide() {
      document.getElementById('layout--content').classList.remove('--mainMenuOpen')
    },
    dropdownShow() {
      document.getElementById('layout--content').classList.add('--mainMenuOpen')
    },
  },
}
