export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PROMOTION.IMPRESSION, ({ promotion }) => {
    window.dataLayer.push({
      event: 'promotionImpression',
      ecommerce: {
        promoView: {
          promotions: [
            {
              name: promotion.name,
              creative_name: promotion.creative_name,
              creative_slot: promotion.creative_slot,
            },
          ],
        },
      },
    })
  })
}
