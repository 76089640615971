import contentSettings from '@site/content.settings'

export default ({ $axios, store, app }) => {
  // Ensures there are user-variant and gropus set on client side
  $axios.defaults.headers.common['x-inspishop-user-variant'] = store.state.auth.variant
  $axios.defaults.headers.common['x-inspishop-user-groups'] = store.state.auth.groups
    ? store.state.auth.groups.join(',')
    : ''

  // Build and set user menu to store
  const userMenu = [
    {
      title: app.i18n.t('globals.userMenu.dashboard'),
      path: { name: 'user-dashboard' },
    },
    {
      title: app.i18n.t('globals.userMenu.settings'),
      path: { name: 'user-settings' },
    },
    {
      title: app.i18n.t('globals.userMenu.orders'),
      path: { name: 'user-orders' },
    },
    {
      title: app.i18n.t('globals.userMenu.password'),
      path: { name: 'user-password' },
    },
  ]
  if (contentSettings.functions.documentsList) {
    userMenu.push({
      title: app.i18n.t('globals.userMenu.documentsList'),
      path: { name: 'user-documentsList' },
    })
  }
  if (contentSettings.functions.warrantyClaims) {
    userMenu.push({
      title: app.i18n.t('globals.userMenu.warrantyClaims'),
      path: { name: 'warrantyClaims' },
      link_target_blank: true,
    })
  }
  if (store.state.globals.settings.functions.shopping_lists && contentSettings.functions.shoppingLists) {
    userMenu.push({
      title: app.i18n.t('globals.userMenu.shoppingLists'),
      path: { name: 'user-shoppingLists' },
    })
  }
  if (store.state.globals.settings.functions.users_addresses) {
    userMenu.push({
      title: app.i18n.t('globals.userMenu.addressList'),
      path: { name: 'user-addressList' },
    })
  }
  if (contentSettings.functions.favourites) {
    userMenu.push({
      title: app.i18n.t('globals.userMenu.favourites'),
      path: { name: 'user-favourites' },
    })
  }
  userMenu.push({
    title: 'Import objednávky',
    path: { name: 'checkout-importOrder' },
  })
  store.commit('globals/SET_USER_MENU', userMenu)
}
