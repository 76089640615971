export default function productTransformer(item, unitsWithTax = true, amount) {
  return item
    ? {
        name: item.product.name,
        id: item.product.id,
        price: unitsWithTax ? item.prices.unitWithTax : item.prices.unitWithoutTax,
        category: item.product.categoryFullname,
        variant: item.shoppable.name !== item.product.name ? item.shoppable.name : '',
        quantity: amount ? amount : item.amount,
        dimension1: item.shoppable.availability.isAvailable, // Special setting for Nobilis only, dimension1 represent product's stock availability
      }
    : null
}
