export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.PROMOTION.CLICK, ({ promotion }) => {
    window.dataLayer.push({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: promotion.name,
              creative_name: promotion.creative_name,
              creative_slot: promotion.creative_slot,
            },
          ],
        },
      },
    })
  })
}
