import { getters, mutations, actions as parentActions, state } from '~/sites/shop/store/globals'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async FETCH_GLOBALS_CMS({ commit }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const promises = {
        cms: client.query({
          query: gql`
            query {
              configuration_block_contact {
                block_contact_image {
                  path
                  alt
                }
                customer_service_opening_hours
                customer_service_phone
                store_opening_hours_long
                store_2_opening_hours_long
                store_opening_hours_short
                tearoom_opening_hours_long
                employees {
                  entity {
                    ... on employee {
                      _id
                      title
                      position
                      email
                      phone
                      photo {
                        title
                        alt
                        path
                      }
                    }
                  }
                }
              }
              configuration_products_most_sold {
                title
              }
              configuration_cart {
                cart_methods_info_text
                shopping_disabled
                shopping_disabled_text
              }
              configuration_error_pages {
                error_image {
                  path
                  alt
                }
                content
                title
              }
              configuration_products {
                show_free_delivery_tag_by_price
              }
              configuration_footer {
                copyright_name
                copyright_year
                footer_text
                footer_image_1 {
                  path
                  alt
                }
                social_links {
                  entity {
                    ... on social_link {
                      title
                      social_type
                      url
                    }
                  }
                }
              }
              configuration_top_banner {
                enabled
                background_color
                font_color
                icon
                icon_text
                lastModified
                text
              }
            }
          `,
        }),
      }
      const result = await Promise.allValues(promises)

      delete result.cms.data.configuration_block_contact.__typename
      delete result.cms.data.configuration_footer.__typename
      delete result.cms.data.configuration_top_banner.__typename

      // TODO: Refactor to set dynamically
      commit('SET_FIELD', {
        field: 'configuration_products',
        value: result.cms.data.configuration_products,
      })
      commit('SET_FIELD', {
        field: 'block_contact',
        value: result.cms.data.configuration_block_contact,
      })
      commit('SET_FIELD', {
        field: 'cartConfiguration',
        value: result.cms.data.configuration_cart || {},
      })
      commit('SET_FIELD', {
        field: 'errorPages',
        value: result.cms.data.configuration_error_pages || {},
      })
      commit('SET_FIELD', {
        field: 'footer',
        value: result.cms.data.configuration_footer,
      })
      commit('SET_FIELD', {
        field: 'topBanner',
        value: result.cms.data.configuration_top_banner,
      })
      commit('SET_FIELD', {
        field: 'mostSoldProductsTitle',
        value: result.cms.data.configuration_products_most_sold.title,
      })
    } catch (e) {
      console.log(e)
    }
  },
}

export { actions, getters, state, mutations }
