import AppImage from '@theme/components/atom/AppImage'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    Wysiwyg,
    AppImage,
  },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'landing_text',
  },
  query() {
    return `
      ... on block_landing_text {
        type
        uuid
        text
        textAlign
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            width
            height
            offsetX
            offsetY
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
