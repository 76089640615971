import Dropdown from '@theme/components/utils/Dropdown'

export default {
  components: {
    Dropdown,
  },
  props: {
    dropdown: {
      type: Boolean,
      default: true,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      domains: this.$store.state.globals.languages.map(language => ({
        ...language.domain,
        humanReadableStorefrontDomain: language.domain.storefrontUrl.replace('https://', ''),
        canonicalUrl: this.$store.state.locale.canonicalUrls.find(url => url.language === language.domain.languageCode),
      })),
      domainsFiltered: [],
    }
  },
  mounted() {
    this.domainsFiltered = this.domains.filter(domain => domain.languageCode !== this.$i18n.locale)
  },
}
