import WysiwygNode from '@theme/components/utils/Wysiwyg/helpers/WysiwygNode'

export default {
  components: {
    WysiwygNode,
  },
  props: {
    content: {
      type: Object,
    },
    wysiwygWrapperType: {
      type: String,
    },
  },
}
