import { isNotAlreadySent, logSent } from '~/events/duplicateHelpers'
import axios from 'axios'
import discountTransformer from './transformers/discount.transformer'
import jsCookie from 'js-cookie'
import productTransformer from './transformers/product.transformer'

export default ({ eventBus, eventDefinitions, context }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const ALREADY_SENT_KEY = 'GTM_EE_SENT_ORDER_IDS'
    if (isNotAlreadySent(ALREADY_SENT_KEY, order.code)) {
      logSent(ALREADY_SENT_KEY, order.code)

      const couponItems = order.items.filter(item => item.attributes && item.attributes.discount)
      const settings = context.app.$contentSettings.events.enhancedEcommerce.purchase

      let revenueArray = [order.prices[`items${settings.unitsWithTax ? 'With' : 'Without'}Tax`]]
      if (settings.revenueIncludeShipperPrice) {
        revenueArray.push(order.prices[`shipperPrice${settings.unitsWithTax ? 'With' : 'Without'}Tax`])
      }
      if (settings.revenueIncludePaymentPrice) {
        revenueArray.push(order.prices[`paymentPrice${settings.unitsWithTax ? 'With' : 'Without'}Tax`])
      }
      const revenue = revenueArray.reduce((a, b) => a + b, 0)

      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.code,
              affiliation: 'Online Store',
              revenue: revenue,
              tax: order.prices.totalWithTax - order.prices.totalWithoutTax,
              shipping: settings.unitsWithTax ? order.prices.shipperPriceWithTax : order.prices.shipperPriceWithoutTax,
              coupon: couponItems.length > 0 ? discountTransformer(couponItems[0]) : '',
              currency: order.currencyCode,
            },
            products: order.items
              .slice(0, 15)
              .filter(cartItem => !(cartItem.attributes && cartItem.attributes.discount))
              .map(cartItem => productTransformer(cartItem, settings.unitsWithTax)),
            customer: {
              email: order.customer.email,
              firstname: order.customer.firstname,
            },
          },
        },
      })

      if (order.currencyCode === 'CZK') {
        const payload = {
          v: 1,
          t: 'pageview',
          tid: 'UA-38949493-3',
          cid: jsCookie.get('_ga'),
          dh: location.hostname,
          dp: location.pathname,
          dt: document.title,
          cu: order.currencyCode,
          tt: order.prices.totalWithTax - order.prices.totalWithoutTax,
          ts: settings.unitsWithTax ? order.prices.shipperPriceWithTax : order.prices.shipperPriceWithoutTax,
          ti: order.code,
          tr: revenue,
          pa: 'purchase',
          ec: 'ecommerce',
          ea: 'purchase',
          el: order.code,
        }
        order.items
          .filter(cartItem => !(cartItem.attributes && cartItem.attributes.discount))
          .forEach((cartItem, index) => {
            payload[`pr${index + 1}id`] = cartItem.product.id
            payload[`pr${index + 1}nm`] = cartItem.product.name
          })
        try {
          axios.get('https://www.google-analytics.com/collect', {
            params: payload,
          })
        } catch (e) {
          console.log(e)
        }
      }
    }
  })
}
