import AppImage from '@theme/components/atom/AppImage'
import ContentBuilderBlock from '~/mixins/ContentBuilderBlock'

export default {
  name: 'LandingPageListBlock',
  components: { AppImage },
  mixins: [ContentBuilderBlock],
  cmsSettings: {
    type: 'landing_page_list',
  },
  query() {
    return `
      ... on block_landing_page_list {
        type
        uuid
        landing_page_reference {
          entityId
          entity {
            ... on content_page {
              _id
              title
              subtitle
              box_color
              box_image {
                alt
                path
              }
              page_meta {
                slug
              }
            }
            ... on landing_page {
              _id
              title
              subtitle
              box_color
              box_image {
                alt
                path
              }
              page_meta {
                slug
              }
            }
          }
        }
      }
    `
  },
}
