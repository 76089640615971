import NewsletterForm from '~/themes/base/components/shop/NewsletterForm/NewsletterForm.js'

export default {
  mixins: [NewsletterForm],
  props: {
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
}
