export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconPosition: {
      type: String,
      default: 'left',
    },
    iconSize: {
      type: String,
      default: 'default',
    },
    link: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      icons: {
        person: require('@icon/person.svg?raw'),
        'maps-and-flags': require('@icon/maps-and-flags.svg?raw'),
        'triangle-down': require('@icon/triangle-down.svg?raw'),
      },
    }
  },
  computed: {
    tag() {
      return this.link ? 'a' : 'span'
    },
  },
}
