import AppLink from '@theme/components/atom/AppLink'
import DomainSwitcher from '@theme/components/shop/DomainSwitcher'
import IconLink from '@theme/components/molecule/IconLink'
import LanguageSwitcher from '@theme/components/shop/LanguageSwitcher'

export default {
  components: {
    AppLink,
    DomainSwitcher,
    IconLink,
    LanguageSwitcher,
  },
  props: {
    contact: {
      type: Object,
      default() {
        return this.$store.state.globals.contact
      },
    },
    eshopMenu: {
      type: Boolean,
      default: true,
    },
    menuTop: {
      type: Object,
      default() {
        return this.$store.state.globals.menus.headerTop
      },
    },
  },
  data() {
    return {
      open: false,
      openLevel: false,
      selectedItem: null,
    }
  },
  destroyed() {
    document.body.classList.remove('---mobileMenuOpen')
  },
  methods: {
    toggleMenu() {
      this.open = !this.open
      if (this.open) {
        document.body.classList.add('---mobileMenuOpen')
      } else {
        document.body.classList.remove('---mobileMenuOpen')
      }
    },
    toggleSubLevel(item) {
      this.openLevel = !this.openLevel
      this.selectedItem = this.selectedItem !== item ? item : null
    },
  },
  watch: {
    async open(newValue) {
      await this.$store.dispatch('menu/SET_MAIN_MENU_OPENED', { state: newValue })
    },
  },
}
