import { notifications } from '~/app/notifications'

export default {
  props: {
    initial: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: Infinity,
    },
    step: {
      type: Number,
      default: 1,
    },
    unit: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: this.initial,
    }
  },
  watch: {
    value() {
      if (this.max && this.value > this.max) {
        this.value = this.max
        notifications.info(
          this.$options.filters.prepositions(this.$t('AppNumber.maxAmountReached', { maxAmount: this.max })),
        )
      }
    },
  },
  methods: {
    change(e) {
      this.update(Number(e.target.value))
    },
    plus() {
      let value = this.value
      if (!this.max || value < this.max) value = Number(value) + Number(this.step)
      if (this.max > 0 && value > this.max) value = this.max // Fix in case of overstep
      if (this.max > 0 && value >= this.max) {
        notifications.info(
          this.$options.filters.prepositions(this.$t('AppNumber.maxAmountReached', { maxAmount: this.max })),
        )
      }
      this.update(value)
    },
    minus() {
      let value = this.value
      if (value > this.min) value = Number(value) - Number(this.step)
      if (value < this.min) value = this.min // Fix in case of overstep
      this.update(value)
    },
    update(value) {
      this.value = value
      this.$emit('update', value)
    },
  },
}
