export default function({ app }) {
  const cookieBotUrl = 'https://consent.cookiebot.com/uc.js'
  app.head.script.push({
    src: cookieBotUrl,
    async: true,
    ['data-cbid']: '87c15739-83bc-4aab-804d-4f29373e7aab',
    ['data-blockingmode']: 'auto',
    type: 'text/javascript',
    id: 'Cookiebot',
  })
}
