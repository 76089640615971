import LandingProductCardsBlock from '~/themes/base/blocks/LandingProductCardsBlock'

export default {
  ...LandingProductCardsBlock,
  query() {
    return `
      ... on block_landing_product_cards {
        type
        uuid
        product_cards {
          ... on landing_block_product_card {
            position
            title
            tag
            description
            image {
              path
              alt
            }
            product_reference {
              entityId
            }
            button_color
            cta_label
          }
        }
        padding_top
        padding_bottom
        images {
          ... on landing_block_background_image {
            isWallpaper
            positionX
            positionY
            offsetX
            offsetY
            width
            height
            image {
              path
              alt
            }
          }
        }
        divider {
          ... on landing_block_divider {
            height
            width
            rotation
            title
            description
            image {
              path
              alt
            }
          }
        }
        background_color
      }
    `
  },
}
