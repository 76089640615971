export default ({ eventBus, eventDefinitions }) => {
  eventBus.$on(eventDefinitions.ORDER.PLACED, ({ order }) => {
    const orderProducts = order.items.map(item => ({
      product_id: item.variant.catalogCode,
      price: item.prices.unitWithTax,
      quantity: item.amount,
    }))
    window.Saimon = window.Saimon || {}
    window.Saimon.eventBuffer = window.Saimon.eventBuffer || []
    window.Saimon.eventBuffer.push({
      event_type: 'purchase',
      order_id: order.code,
      products: orderProducts,
      price: orderProducts.reduce((acc, product) => acc + product.price, 0),
    })
  })
}
