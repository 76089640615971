import gql from 'graphql-tag'

export const actions = {
  async LOAD_INTERESTING_ARTICLES({ commit, dispatch }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const result = await client.query({
        query: gql`
          query {
            interesting(limit: 6) {
              entities {
                _id
                main_image {
                  path
                  alt
                }
                order
                title
                url
              }
            }
          }
        `,
      })
      return result.data.interesting.entities
    } catch (e) {
      console.error(e)
    }
  },
}
