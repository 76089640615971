import { actions as parentActions, mutations as parentMutations, state as parentState } from '~/sites/shop/store/menu'

const actions = {
  ...parentActions,
  async SET_MAIN_MENU_OPENED({ commit }, { state }) {
    await commit('SET_MAIN_MENU_OPENED', state)
  },
}

const mutations = {
  ...parentMutations,
  async SET_MAIN_MENU_OPENED(state, value) {
    state.mainMenuMobileOpened = value
  },
}

const state = () => ({
  ...parentState(),
  mainMenuMobileOpened: false,
})

export { actions, mutations, state }
