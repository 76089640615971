import { actions as parentActions } from '~/sites/shop/store/banner'
import gql from 'graphql-tag'

const actions = {
  ...parentActions,
  async LOAD_HOMEPAGE_BANNERS() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const query = await client.query({
        query: gql`
          query {
            banner_eshop {
              entities {
                _id
                background_color_right
                background_color_left
                box_color
                main_image {
                  path
                  alt
                }
                main_image_translatable {
                  path
                  alt
                }
                main_image_mobile {
                  path
                  alt
                }
                main_image_mobile_translatable {
                  path
                  alt
                }
                order
                title
                text
                text_color
                url
                time_limited
                time_limit_start
                time_limit_end
              }
            }
          }
        `,
      })
      return query.data.banner_eshop.entities.filter(v => v.main_image && v.main_image.length > 0).filter(banner => {
        if (!banner.time_limited) {
          return true
        } else if (banner.time_limited && banner.time_limit_start !== null && banner.time_limit_end !== null) {
          return (
            new Date(banner.time_limit_start).valueOf() < Date.now() &&
            Date.now() < new Date(banner.time_limit_end).valueOf()
          )
        }
        return false
      })
    } catch (e) {
      console.error(e)
    }
  },
  async LOAD_HOMEPAGE_BOXES() {
    try {
      const client = this.app.apolloProvider.defaultClient
      const boxRight = await client.query({
        query: gql`
          query {
            configuration_homepage_box_right {
              button_text
              image {
                path
                alt
              }
              text
              url
            }
          }
        `,
      })
      const boxLeft = await client.query({
        query: gql`
          query {
            configuration_homepage_box_left {
              button_text
              image {
                path
                alt
              }
              text
              url
            }
          }
        `,
      })
      return [boxLeft.data.configuration_homepage_box_left, boxRight.data.configuration_homepage_box_right]
    } catch (e) {
      console.error(e)
    }
  },
}

export { actions }
