import { find, isEqual, sortBy, uniqWith } from 'lodash'
import AddToCart from '@theme/components/cart/AddToCart'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import AppTitle from '@theme/components/atom/AppTitle'
import Badge from '@theme/components/atom/Badge'
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import Dropdown from '@theme/components/utils/Dropdown'
import DropdownContent from '@theme/components/utils/DropdownContent'
import HeadingLevel from '~/mixins/HeadingLevel'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductBoxMixin from '~/mixins/ProductBoxMixin'
import ProductCreditsMixin from '~/mixins/ProductCreditsMixin'
import ProductImpressionMixin from '~/mixins/ProductImpressionMixin'
import ProductMixin from '~/mixins/ProductMixin'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'
import Tag from '@theme/components/atom/Tag'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  mixins: [
    BusinessUserMixin,
    HeadingLevel,
    ProductCreditsMixin,
    ProductBoxMixin,
    ProductImpressionMixin,
    ProductMixin,
    ScrollToEventMixin,
  ],
  components: {
    AddToCart,
    AppImage,
    AppNumber,
    AppTitle,
    Badge,
    Dropdown,
    DropdownContent,
    ProductAvailability,
    Tag,
  },
  data() {
    return {
      quantity: 1,
    }
  },
  created() {
    if (this.activeVariantId === this.product.defaultVariantId) {
      this.activeVariantId = this.variantsOrdered[0].id
    }
  },
  mounted() {
    this.$on('scrolledTo', () => {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.IMPRESSION, {
        product: this.product,
      })
    })
  },
  computed: {
    percentageDiscount() {
      return Math.round(this.product.prices.percentageDiscount)
    },
    activeVariant() {
      return this.product.hasVariants
        ? find(this.product.variants, v => v.id === this.activeVariantId) || this.product.variants[0]
        : this.product.variants[0]
    },
    name() {
      return this.$themeSettings.components.ProductBox.showVariantFullName
        ? this.activeVariant.fullname
        : this.product.name
    },
    variantsOrdered() {
      const COURSES_CATEGORY_ID = 304
      if (this.product.categoryId === COURSES_CATEGORY_ID) {
        const sortedVariants = this.product.variants.map(variant => variant)
        const variantDates = sortedVariants.reduce((acc, variant) => {
          return {
            ...acc,
            [variant.id]:
              variant.content &&
              variant.content.course_date_start &&
              variant.content.course_date_start[this.$i18n.locale],
          }
        }, {})
        sortedVariants.sort((a, b) => {
          if (!variantDates[a.id]) {
            return -1
          }
          if (!variantDates[b.id]) {
            return 1
          }
          return variantDates[a.id].localeCompare(variantDates[b.id])
        })
        return sortedVariants
      } else {
        return sortBy(this.product.variants, [variant => parseFloat(variant.name)])
      }
    },
    allTags() {
      return this.activeVariant.tags && this.activeVariant.tags.length > 0
        ? uniqWith([...this.product.tags, ...this.activeVariant.tags], isEqual)
        : this.product.tags
    },
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.CLICK, { product: this.product })
    },
  },
}
