import { hydrateWhenVisible } from 'vue-lazy-hydration'

import CookieConsent from '@theme/components/shop/CookieConsent'
import HeaderMain from '@theme/components/shop/HeaderMain'
import MainMenu from '@theme/components/shop/MainMenu'

const FooterContact = () => import('@theme/components/shop/FooterContact')
const FooterCopyright = () => import('@theme/components/shop/FooterCopyright')
const FooterLogos = () => import('@theme/components/shop/FooterLogos')
const FooterMenu = () => import('@theme/components/shop/FooterMenu')
const FooterReduced = () => import('@theme/components/shop/FooterReduced')
const HeaderSearch = () => import('@theme/components/shop/HeaderSearch')
const HeaderTop = () => import('@theme/components/shop/HeaderTop')

export default {
  components: {
    CookieConsent,
    FooterContact: hydrateWhenVisible(FooterContact),
    FooterCopyright: hydrateWhenVisible(FooterCopyright),
    FooterLogos: hydrateWhenVisible(FooterLogos),
    FooterMenu: hydrateWhenVisible(FooterMenu),
    FooterReduced: hydrateWhenVisible(FooterReduced),
    HeaderSearch: hydrateWhenVisible(HeaderSearch),
    HeaderMain,
    HeaderTop: hydrateWhenVisible(HeaderTop),
    MainMenu,
    Sticky: () => import('@theme/components/utils/Sticky'),
    TopBanner: () => import('@theme/components/content/TopBanner'),
  },
  props: {
    contentRole: {
      type: String,
    },
    isPageTypeHome: {
      type: Boolean,
      default: false,
    },
    noFooterMargin: {
      type: Boolean,
      default: false,
    },
    withBigLogo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  computed: {
    getLayoutClass() {
      return [{ withBigLogo: this.withBigLogo }, { isPageTypeHome: this.isPageTypeHome }]
    },
  },
}
