import Vue from 'vue'

const RoutesPlugin = {
  install(Vue) {
    Vue.prototype.$pathTransform = function(path) {
      if (typeof path === 'string') {
        if (path.match(/((mailto|tel|sms|geo|market):)|:\/\//)) {
          return path
        } else {
          if (path && path.includes('?')) {
            const pathWithoutQuery = path.split('?')[0].replace(/\/$/, '')
            const queryString = path.split('?')[1]
            const query = {}
            for (const queryParameterString of queryString.split('&')) {
              query[queryParameterString.split('=')[0]] = queryParameterString.split('=')[1]
            }
            return this.localePath({ name: 'all', params: [pathWithoutQuery.replace(/^\/+/, '')], query }).replace(
              /\/+$/,
              '/',
            )
          } else {
            return this.localePath({ name: 'all', params: [path.replace(/^\/+/, '')] })
              .replace(/\/+$/, '/')
              .replace(`/${this.$i18n.locale}/${this.$i18n.locale}/`, `/${this.$i18n.locale}/`)
          }
        }
      } else if (typeof path === 'object') {
        if (path.name) {
          return this.localePath(path)
        }
        if (path.contentType) {
          if (path.contentType === 'page') {
            return this.localePath({ name: 'page-slug', params: { slug: path.slug } })
          }
          if (path.contentType === 'content_page') {
            return this.localePath({ name: 'all', params: [path.slug] })
          }
          if (path.contentType === 'branch') {
            return this.localePath({ name: 'branch-slug', params: { slug: path.slug } })
          }
          if (path.contentType === 'article') {
            return this.localePath({ name: 'article-slug', params: { slug: path.slug } })
          }
          if (path.contentType === 'article_category') {
            return this.localePath({ name: 'articleList-slug', params: { slug: path.slug } })
          }
          if (path.contentType === 'article_tag') {
            return this.localePath({ name: 'articleTag-slug', params: { slug: path.slug } })
          }
        }
      }
      return '/'
    }
  },
}

Vue.use(RoutesPlugin)
