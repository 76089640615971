import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.NEWSLETTER.SUBSCRIBED, ({ email }) => {
  window.dataLayer.push({
    action: 'odeslat',
    category: 'form',
    email: email,
    event: 'gaevent',
    label: 'newsletter',
  })
})
