import { hydrateWhenVisible } from 'vue-lazy-hydration'
import Carousel from '@theme/components/utils/Carousel'
import ProductsBlock from '~/themes/base/blocks/ProductsBlock'

export default {
  extends: ProductsBlock,
  cmsSettings: {
    type: 'products',
  },
  components: {
    Carousel: hydrateWhenVisible(Carousel),
  },
  query() {
    return `
      ... on block_products {
        type
        uuid
        product_reference {
          entityId
        }
      }
    `
  },
}
