import catalogDataFragment from './catalogDataFragment'
import catalogSettingsFragments from './catalogSettings'
import gql from 'graphql-tag'
import linkEmbeddedFragments from './linkEmbedded'

export default {
  all: gql`
    ${linkEmbeddedFragments.base}
    ${catalogDataFragment.all('category')}
    ${catalogSettingsFragments.all}
    fragment categoryFragmentAll on category {
      _id
      catalog_settings {
        ...catalogSettingsFragmentAll
      }
      ...catalogDataFragment_category
      category_banner {
        path
      }
      category_banner_background_color_left
      category_banner_background_color_right
      category_banner_text_color
      category_description
      category_perex
      visible_subcategory
    }
  `,
}
