import { errorFromResponse, notification } from '~/app/notifications'
import { getters, mutations, actions as parentActions, state as parentState } from '~/sites/shop/store/auth'

const actions = {
  ...parentActions,
  async LOGIN({ commit, state, dispatch, rootState }, { email, password, redirect }) {
    try {
      const request = await this.$axios.$post(this.$env.STORE_URL + '/api/oauth/login', {
        email,
        password,
      })
      await dispatch('SET_AUTH', request.data.access_token)
      await dispatch('SET_AXIOS_TOKEN')
      await dispatch('user/FETCH_USER', { forceReload: true }, { root: true })
      if (await dispatch('RESTRICTED_GROUP')) {
        return false
      }
      await dispatch('cart/FETCH_CART', { forceReload: true }, { root: true })
      window.location = redirect ? redirect : this.app.localePath({ name: 'index' })
      return true
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.errors &&
        e.response.data.errors.some(error => error.code === 301)
      ) {
        const userOnDifferentDomainError = e.response.data.errors.find(error => error.code === 301)
        notification(userOnDifferentDomainError.title, 'info', { duration: 3000 })
        setTimeout(
          () =>
            window.location.replace(
              `${userOnDifferentDomainError.redirectTo}${
                userOnDifferentDomainError.redirectTo.endsWith('/') ? '' : '/'
              }prihlasit?email=${userOnDifferentDomainError.email}`,
            ),
          3000,
        )
        return
      }
      errorFromResponse(e.response)
      return false
    }
  },
}

const state = () => ({
  ...parentState(),
  restrictedGroups: ['VO'],
})

export { actions, getters, mutations, state }
