import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6194850e = () => interopDefault(import('../sites/nobilis/pages/user/activate.vue' /* webpackChunkName: "sites/nobilis/pages/user/activate" */))
const _6356b4f2 = () => interopDefault(import('../sites/nobilis/pages/blocksettings.vue' /* webpackChunkName: "sites/nobilis/pages/blocksettings" */))
const _38a4f3b0 = () => interopDefault(import('../sites/nobilis/pages/aromatherapy.vue' /* webpackChunkName: "sites/nobilis/pages/aromatherapy" */))
const _1cc48c8e = () => interopDefault(import('../sites/nobilis/pages/articleList/index.vue' /* webpackChunkName: "sites/nobilis/pages/articleList/index" */))
const _4fcbe2a8 = () => interopDefault(import('../sites/nobilis/pages/ean/index.vue' /* webpackChunkName: "sites/nobilis/pages/ean/index" */))
const _1b8a8849 = () => interopDefault(import('../sites/nobilis/pages/entry.vue' /* webpackChunkName: "sites/nobilis/pages/entry" */))
const _7e2560b8 = () => interopDefault(import('../sites/nobilis/pages/search/index.vue' /* webpackChunkName: "sites/nobilis/pages/search/index" */))
const _13490a7a = () => interopDefault(import('../sites/nobilis/pages/contact/index.vue' /* webpackChunkName: "sites/nobilis/pages/contact/index" */))
const _35d3d4fc = () => interopDefault(import('../sites/nobilis/pages/checkout/index.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/index" */))
const _7de0140e = () => interopDefault(import('../sites/nobilis/pages/mobileApp/index.vue' /* webpackChunkName: "sites/nobilis/pages/mobileApp/index" */))
const _f569a520 = () => interopDefault(import('../sites/nobilis/pages/faq/index.vue' /* webpackChunkName: "sites/nobilis/pages/faq/index" */))
const _2f78c438 = () => interopDefault(import('../sites/nobilis/pages/about.vue' /* webpackChunkName: "sites/nobilis/pages/about" */))
const _52065c8e = () => interopDefault(import('../sites/nobilis/pages/shoppingGuide/index.vue' /* webpackChunkName: "sites/nobilis/pages/shoppingGuide/index" */))
const _5cb9c880 = () => interopDefault(import('../sites/nobilis/pages/login.vue' /* webpackChunkName: "sites/nobilis/pages/login" */))
const _c75441c4 = () => interopDefault(import('../sites/nobilis/pages/branch/index.vue' /* webpackChunkName: "sites/nobilis/pages/branch/index" */))
const _726b651c = () => interopDefault(import('../sites/nobilis/pages/register.vue' /* webpackChunkName: "sites/nobilis/pages/register" */))
const _1a905b4b = () => interopDefault(import('../sites/nobilis/pages/passwordReset.vue' /* webpackChunkName: "sites/nobilis/pages/passwordReset" */))
const _07057a8e = () => interopDefault(import('../sites/nobilis/pages/sitemapconfig.vue' /* webpackChunkName: "sites/nobilis/pages/sitemapconfig" */))
const _f0c361ac = () => interopDefault(import('../sites/nobilis/pages/maintenance.vue' /* webpackChunkName: "sites/nobilis/pages/maintenance" */))
const _40488a47 = () => interopDefault(import('../sites/nobilis/pages/user/index.vue' /* webpackChunkName: "sites/nobilis/pages/user/index" */))
const _821d5d70 = () => interopDefault(import('../sites/nobilis/pages/aboutPurchase/index.vue' /* webpackChunkName: "sites/nobilis/pages/aboutPurchase/index" */))
const _6d8496d4 = () => interopDefault(import('../sites/nobilis/pages/passwordForgotten.vue' /* webpackChunkName: "sites/nobilis/pages/passwordForgotten" */))
const _94cbbe3e = () => interopDefault(import('../sites/nobilis/pages/brand/index.vue' /* webpackChunkName: "sites/nobilis/pages/brand/index" */))
const _4d4a5a2e = () => interopDefault(import('../sites/nobilis/pages/checkout/info.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/info" */))
const _8fe2001c = () => interopDefault(import('../sites/nobilis/pages/checkout/finished.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/finished" */))
const _9edb3b3c = () => interopDefault(import('../sites/nobilis/pages/checkout/methods.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/methods" */))
const _7c6bccc8 = () => interopDefault(import('../sites/nobilis/pages/checkout/importOrder/index.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/importOrder/index" */))
const _baa981f6 = () => interopDefault(import('../sites/nobilis/pages/user/shoppingLists/index.vue' /* webpackChunkName: "sites/nobilis/pages/user/shoppingLists/index" */))
const _f5ffb57a = () => interopDefault(import('../sites/nobilis/pages/user/orders/index.vue' /* webpackChunkName: "sites/nobilis/pages/user/orders/index" */))
const _6eb75784 = () => interopDefault(import('../sites/nobilis/pages/user/settings.vue' /* webpackChunkName: "sites/nobilis/pages/user/settings" */))
const _48dc6489 = () => interopDefault(import('../sites/nobilis/pages/user/dashboard.vue' /* webpackChunkName: "sites/nobilis/pages/user/dashboard" */))
const _7dd7fe67 = () => interopDefault(import('../sites/nobilis/pages/user/addressList.vue' /* webpackChunkName: "sites/nobilis/pages/user/addressList" */))
const _6ad15294 = () => interopDefault(import('../sites/nobilis/pages/user/password.vue' /* webpackChunkName: "sites/nobilis/pages/user/password" */))
const _67edcfae = () => interopDefault(import('../sites/nobilis/pages/checkout/payment/callback.vue' /* webpackChunkName: "sites/nobilis/pages/checkout/payment/callback" */))
const _07fb78a9 = () => interopDefault(import('../sites/nobilis/pages/index.vue' /* webpackChunkName: "sites/nobilis/pages/index" */))
const _08d182a6 = () => interopDefault(import('../sites/nobilis/pages/user/shoppingLists/_id.vue' /* webpackChunkName: "sites/nobilis/pages/user/shoppingLists/_id" */))
const _d466c72a = () => interopDefault(import('../sites/nobilis/pages/user/orders/_id.vue' /* webpackChunkName: "sites/nobilis/pages/user/orders/_id" */))
const _20462a7f = () => interopDefault(import('../sites/nobilis/pages/author/_slug.vue' /* webpackChunkName: "sites/nobilis/pages/author/_slug" */))
const _372102f0 = () => interopDefault(import('../sites/nobilis/pages/article/_slug.vue' /* webpackChunkName: "sites/nobilis/pages/article/_slug" */))
const _7f1b356e = () => interopDefault(import('../sites/nobilis/pages/articleList/_slug/index.vue' /* webpackChunkName: "sites/nobilis/pages/articleList/_slug/index" */))
const _cac18454 = () => interopDefault(import('../sites/nobilis/pages/branch/_slug.vue' /* webpackChunkName: "sites/nobilis/pages/branch/_slug" */))
const _a5b8c97e = () => interopDefault(import('../sites/nobilis/pages/product/_slug.vue' /* webpackChunkName: "sites/nobilis/pages/product/_slug" */))
const _5d2bad44 = () => interopDefault(import('../sites/nobilis/pages/landingPageList/index.vue' /* webpackChunkName: "sites/nobilis/pages/landingPageList/index" */))
const _e0668a7a = () => interopDefault(import('../sites/nobilis/pages/page/_slug.vue' /* webpackChunkName: "sites/nobilis/pages/page/_slug" */))
const _74f26594 = () => interopDefault(import('../sites/nobilis/pages/articleList/_slug/_child.vue' /* webpackChunkName: "sites/nobilis/pages/articleList/_slug/_child" */))
const _2a6ab936 = () => interopDefault(import('../sites/nobilis/pages/_.vue' /* webpackChunkName: "sites/nobilis/pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktivovat",
    component: _6194850e,
    name: "user-activate___cs"
  }, {
    path: "/blocksettings",
    component: _6356b4f2,
    name: "blocksettings___cs"
  }, {
    path: "/centrum-aromaterapie",
    component: _38a4f3b0,
    name: "aromatherapy___cs"
  }, {
    path: "/clanky",
    component: _1cc48c8e,
    name: "articleList___cs"
  }, {
    path: "/ean",
    component: _4fcbe2a8,
    name: "ean___cs"
  }, {
    path: "/entry",
    component: _1b8a8849,
    name: "entry___cs"
  }, {
    path: "/hledat",
    component: _7e2560b8,
    name: "search___cs"
  }, {
    path: "/kontakt",
    component: _13490a7a,
    name: "contact___cs"
  }, {
    path: "/kosik",
    component: _35d3d4fc,
    name: "checkout___cs"
  }, {
    path: "/mobilni-aplikace",
    component: _7de0140e,
    name: "mobileApp___cs"
  }, {
    path: "/nejcastejsi-dotazy",
    component: _f569a520,
    name: "faq___cs"
  }, {
    path: "/o-nas",
    component: _2f78c438,
    name: "about___cs"
  }, {
    path: "/poradna",
    component: _52065c8e,
    name: "shoppingGuide___cs"
  }, {
    path: "/prihlasit",
    component: _5cb9c880,
    name: "login___cs"
  }, {
    path: "/prodejny",
    component: _c75441c4,
    name: "branch___cs"
  }, {
    path: "/registrovat",
    component: _726b651c,
    name: "register___cs"
  }, {
    path: "/resetovat-heslo",
    component: _1a905b4b,
    name: "passwordReset___cs"
  }, {
    path: "/sitemapconfig",
    component: _07057a8e,
    name: "sitemapconfig___cs"
  }, {
    path: "/udrzba",
    component: _f0c361ac,
    name: "maintenance___cs"
  }, {
    path: "/uzivatel",
    component: _40488a47,
    name: "user___cs"
  }, {
    path: "/vse-o-nakupu",
    component: _821d5d70,
    name: "aboutPurchase___cs"
  }, {
    path: "/zapomenute-heslo",
    component: _6d8496d4,
    name: "passwordForgotten___cs"
  }, {
    path: "/znacky",
    component: _94cbbe3e,
    name: "brand___cs"
  }, {
    path: "/kosik/dodaci-udaje",
    component: _4d4a5a2e,
    name: "checkout-info___cs"
  }, {
    path: "/kosik/dokonceni",
    component: _8fe2001c,
    name: "checkout-finished___cs"
  }, {
    path: "/kosik/doprava-a-platba",
    component: _9edb3b3c,
    name: "checkout-methods___cs"
  }, {
    path: "/kosik/import-objednavky",
    component: _7c6bccc8,
    name: "checkout-importOrder___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy",
    component: _baa981f6,
    name: "user-shoppingLists___cs"
  }, {
    path: "/uzivatel/objednavky",
    component: _f5ffb57a,
    name: "user-orders___cs"
  }, {
    path: "/uzivatel/osobni-udaje",
    component: _6eb75784,
    name: "user-settings___cs"
  }, {
    path: "/uzivatel/prehled",
    component: _48dc6489,
    name: "user-dashboard___cs"
  }, {
    path: "/uzivatel/seznam-adres",
    component: _7dd7fe67,
    name: "user-addressList___cs"
  }, {
    path: "/uzivatel/zmena-hesla",
    component: _6ad15294,
    name: "user-password___cs"
  }, {
    path: "/checkout/payment/callback",
    component: _67edcfae,
    name: "checkout-payment-callback___cs"
  }, {
    path: "/",
    component: _07fb78a9,
    name: "index___cs"
  }, {
    path: "/uzivatel/nakupni-seznamy/:id",
    component: _08d182a6,
    name: "user-shoppingLists-id___cs"
  }, {
    path: "/uzivatel/objednavky/:id",
    component: _d466c72a,
    name: "user-orders-id___cs"
  }, {
    path: "/autori/:slug",
    component: _20462a7f,
    name: "author-slug___cs"
  }, {
    path: "/clanek/:slug",
    component: _372102f0,
    name: "article-slug___cs"
  }, {
    path: "/clanky/:slug",
    component: _7f1b356e,
    name: "articleList-slug___cs"
  }, {
    path: "/prodejny/:slug",
    component: _cac18454,
    name: "branch-slug___cs"
  }, {
    path: "/produkt/:slug",
    component: _a5b8c97e,
    name: "product-slug___cs"
  }, {
    path: "/rozcestnik/:slug",
    component: _5d2bad44,
    name: "landingPageList___cs"
  }, {
    path: "/stranka/:slug",
    component: _e0668a7a,
    name: "page-slug___cs"
  }, {
    path: "/clanky/:slug/:child",
    component: _74f26594,
    name: "articleList-slug-child___cs"
  }, {
    path: "/*",
    component: _2a6ab936,
    name: "all___cs"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
