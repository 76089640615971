import eventBus from '~/events/eventBus'
import eventDefinitions from '~/events/eventDefinitions'

eventBus.$on(eventDefinitions.USER.REGISTERED, ({ user }) => {
  if (user) {
    window.dataLayer.push({
      action: 'odeslat',
      category: 'form',
      email: user.email,
      event: 'gaevent',
      label: 'registrace uzivatel',
      user_id: user.id,
      user_groups: user.groups,
    })
  }
})
