export default {
  props: {
    tooltipContent: {
      type: String,
      required: true,
    },
    tooltipIcon: {
      type: String,
      default() {
        return require('@icon/question-circle.svg?raw')
      },
    },
    tooltipText: {
      type: String,
      default: '',
    },
    showDelay: {
      type: Number,
      default: 50,
    },
    autoHide: {
      type: Boolean,
      default: false,
    },
    hideDelay: {
      type: Number,
      default: 100,
    },
    trigger: {
      type: String,
      default: 'hover click',
    },
    placement: {
      type: String,
      default() {
        return this.$themeSettings.components.TooltipOpener.placement
      },
    },
  },
  computed: {
    tooltipOptions() {
      return {
        content: this.tooltipContent,
        placement: this.placement,
        delay: { show: this.showDelay, hide: this.hideDelay },
        trigger: this.trigger,
        autoHide: this.autoHide,
        html: true,
      }
    },
  },
}
