import { get } from 'lodash'
import { notification } from '@/app/notifications'
import Vue from 'vue'
import gql from 'graphql-tag'
import linkEmbedded from '~/queries/fragments/linkEmbedded'

export const actions = {
  CUSTOM_CONFIGURATION_CMS_QUERY() {
    return ``
  },
  CUSTOM_CONFIGURATION_CMS_FETCH({ commit }, { result }) {
    return
  },
  async FETCH_COUNTRIES({ commit, state }) {
    if (!state.countries.length) {
      try {
        const url = `${this.$env.STORE_URL}/api/v1/countries`
        const request = await this.$axios.$get(url, { params: { perPage: 100 } })
        commit('SET_COUNTRIES', request.data)
      } catch (e) {
        console.error(e)
      }
    }
  },
  async FETCH_GLOBALS_STORE({ commit, state, dispatch }) {
    try {
      const promises = {
        store: this.$axios.$get(this.$env.STORE_URL + '/api/v1/globals'),
      }
      const result = await Promise.allValues(promises)
      commit('SET_GLOBALS', result.store.data)
      commit('auth/SET_DEFAULT_VARIANT', result.store.data.variantHash, { root: true })
      await dispatch('auth/SET_VARIANT', result.store.data.variantHash, { root: true })
      commit('currency/SET_ACTIVE_CURRENCY', result.store.data.currency, { root: true })
      commit('locale/SET_TRANSLATION_VARIANT', state.domain.translationVariant, { root: true })
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_SHIPPERS({ commit, state }, { force } = {}) {
    if (!state.shippers.length || force) {
      try {
        const request = await this.$axios.$get(this.$env.STORE_URL + '/api/v1/shippers') // TODO: rename to /api/v1/shippers
        commit('SET_SHIPPERS', request.data)
      } catch (e) {
        console.error(e)
      }
    }
  },
  async FETCH_SLUG_METADATA({ commit }) {
    try {
      const slugMetadataSite = this.$contentSettings.slugMetadataSite
      if (slugMetadataSite) {
        const result = await this.app.apolloProvider.defaultClient.query({
          query: gql`
          query {
            configuration_${slugMetadataSite}_metadata {
              title
              name
              description
              keywords
              slugs_metadata {
                ... on slug_metadata {
                  slug
                  title
                  description
                  keywords
                }
              }
            }
          }
        `,
        })
        commit('SET_SLUG_METADATA', get(result, `data.configuration_${slugMetadataSite}_metadata`))
      }
    } catch (e) {
      console.log(e)
    }
  },
  async FETCH_GLOBALS_CMS({ commit, dispatch }) {
    try {
      let client = this.app.apolloProvider.defaultClient
      const promises = {
        cms: client.query({
          query: gql`
            ${linkEmbedded.base}
            query {
              configuration_block_contact {
                block_contact_image {
                  path
                  alt
                }
              }
              configuration_cart {
                cart_methods_shipping_info_text
                cart_methods_payment_info_text
                bonus_club_text
                bonus_club_image {
                  path
                  alt
                }
                shopping_disabled
                shopping_disabled_text
                domain_enabled
              }
              configuration_error_pages {
                error_image {
                  path
                  alt
                }
                content
                title
              }
              configuration_products {
                show_free_delivery_tag_by_price
              }
              configuration_footer {
                copyright_name
                copyright_year
                footer_text
                footer_image_1 {
                  path
                  alt
                }
                social_links {
                  entity {
                    ... on social_link {
                      title
                      social_type
                      url
                    }
                  }
                }
              }
              configuration_top_banner {
                enabled
                background_color
                domain_enabled
                font_color
                icon
                icon_text
                lastModified
                text
              }
              configuration_usp {
                usp_items {
                  ... on usp_item {
                    icon {
                      alt
                      path
                      title
                    }
                    link {
                      ...linkEmbeddedFragmentsBase
                    }
                    title
                  }
                }
              }
              ${await dispatch('CUSTOM_CONFIGURATION_CMS_QUERY')}
            }
          `,
        }),
      }

      const result = await Promise.allValues(promises)
      const data = get(result, 'cms.data')

      // TODO: Refactor to set dynamically
      commit('SET_FIELD', {
        field: 'configuration_products',
        value: get(data, 'configuration_products'),
      })
      commit('SET_FIELD', {
        field: 'block_contact',
        value: get(data, 'configuration_block_contact'),
      })
      commit('SET_FIELD', {
        field: 'cartConfiguration',
        value: get(data, 'configuration_cart'),
      })
      commit('SET_FIELD', {
        field: 'errorPages',
        value: get(data, 'configuration_error_pages'),
      })
      commit('SET_FIELD', {
        field: 'footer',
        value: get(data, 'configuration_footer'),
      })
      commit('SET_FIELD', {
        field: 'topBanner',
        value: get(data, 'configuration_top_banner'),
      })
      commit('SET_FIELD', {
        field: 'uspBlocks',
        value: get(data, 'configuration_usp.usp_items'),
      })
      dispatch('CUSTOM_CONFIGURATION_CMS_FETCH', { result })
    } catch (e) {
      console.log(e)
    }
  },
}

export const state = () => ({
  block_footer: {},
  cartConfiguration: {},
  contact: {},
  cookieConsentClosed: false,
  countries: [],
  currency: {},
  domain: null,
  entryModalsClosed: [],
  redirectModalOpen: false,
  errorPages: {},
  forms: {
    businessTypes: ['b2c', 'b2b'],
    countryCode: 'CZE',
    formalTitles: ['Mr', 'Mrs'],
  },
  footer: {},
  flashMessages: [],
  freeShippingAt: null,
  host: '',
  isLoaded: false,
  languages: [],
  languageBanner: true,
  menus: {},
  meta: {},
  settings: {},
  shippers: [],
  shipperFastest: null,
  slugMetadata: null,
  topBanner: null,
  topBannerLastModifiedClosed: null,
  topCategories: [],
  typoRecognitionSamples: ['google.com', 'seznam.cz', 'centrum.cz'],
  urlRewriteAsyncData: null,
  urlRewriteSlug: null,
  userAgent: '',
  userMenu: null,
  uspBlocks: [],
})

export const mutations = {
  ADD_FLASH_MESSAGE(state, notification) {
    state.flashMessages.push(notification)
  },
  FIRE_FLASH_MESSAGES(state) {
    if (state.flashMessages && state.flashMessages.length > 0) {
      for (let notificationObject of state.flashMessages) {
        notification(notificationObject.text, notificationObject.type, notificationObject.options)
      }
    }
    state.flashMessages = []
  },
  SET_COUNTRIES(state, data) {
    state.countries = data
  },
  SET_REDIRECT_MODAL_STATE(state, value) {
    state.redirectModalOpen = value
  },
  SET_FIELD(state, { field, value }) {
    if (value) {
      if (value.__typename) {
        delete value.__typename
      }
      state[field] = value
    }
  },
  SET_GLOBALS(state, data) {
    state.contact = data.contact
    state.domain = data.domain
    state.freeShippingAt = data.freeShippingAt
    state.isLoaded = true
    state.menus = data.menus
    state.meta = data.meta
    state.languages = data.languages
    state.orderGiftAt = data.orderGiftAt
    state.orderMinimumValue = data.orderMinimumValue
    state.settings = data.settings
  },
  SET_USER_MENU(state, data) {
    state.userMenu = data
  },
  SET_USER_AGENT(state, data) {
    state.userAgent = data
  },
  SET_HOST(state, data) {
    state.host = data
  },
  SET_SHIPPERS(state, data) {
    // TODO: Move to own shippers store module, that will handle duplication of cart and globals shippers
    state.shippers = data

    // Set fastest shipper
    state.shippers.forEach(shipper => {
      if (
        shipper.type !== 'PERSONAL' &&
        (!state.fastestShipper ||
          new Date(shipper.delivery.nearestDeliveryDate) < new Date(state.fastestShipper.delivery.nearestDeliveryDate))
      ) {
        state.fastestShipper = shipper
      }
    })
  },
  SET_SLUG_METADATA(state, data) {
    state.slugMetadata = data
  },
  CLOSE_LANGUAGE_BANNER(state) {
    state.languageBanner = false
  },
  CLOSE_TOP_BANNER(state, lastModified) {
    Vue.set(state, 'topBannerLastModifiedClosed', lastModified)
  },
  CLOSE_COOKIE_CONSENT(state) {
    state.cookieConsentClosed = true
  },
  CLOSE_ENTRY_MODAL(state, id) {
    state.entryModalsClosed.push(id)
  },
}

export const getters = {
  getZasilkovnaApiKey(state) {
    const zasilkovna_api_key = get(state, 'settings.integrations.zasilkovna_api_key')
    return zasilkovna_api_key || 'e02fe964e5f2aecb' // Fallback apiKey (from Tom's private keys' library!)
  },
  isEntryModalClosed: state => entryModalId => {
    return state.entryModalsClosed.includes(entryModalId)
  },
}
